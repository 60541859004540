import { Box, Button, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { logout, useJawadAuthController } from '../context'
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import logo from '../assets/Le_salon_logo_page-0001__1_-removebg-preview.png'

const Header = ({onselect}) => {
    const navigate = useNavigate()
    const [, dispatch] = useJawadAuthController()
    const [search , setSearch] = useState('')
    console.log(search)
    const logUserOut = () => {
        logout(dispatch , null)
        navigate('/')
    }
  return (
    <Box>
    <Box
        sx={{
            display : 'flex',
            alignItems : 'center',
            justifyContent : 'space-between',
            height : '80px',
            marginBottom : '15px'
        }}
    >
        <Typography>
             <img style={{width:'100px'}} src={logo} />
        </Typography>
        <Typography
            color={'#b91c1c'}
            variant='h5'
             sx={{
                fontSize:{xs:'20px' , sm:'25px' , marginLeft:'-15px'},
            }}
        >
            Casher Dashboard
        </Typography>
        
        <Box>
            <Button
                variant='contained'
                color='error'
                onClick={logUserOut}
                sx={{
                    width:{xs:'70px' , sm:'150px'},
                }}
            >
                Logout
            </Button>
        </Box>
    </Box>
    {/* <Box
        sx={{
            display:'flex',
            justifyContent:'center',
            position :'relative',
            color:'black',
            marginTop:'-40px',
            marginBottom:'20px'
        }}
        >
        
        <TextField
         onInput={(e) => {
        setSearch(e.target.value);
      }}
        id="input-with-icon-textfield"
        InputProps={{
          startAdornment: (
              <SearchIcon/>
           
          ),
        }}
        variant="standard"
      />
        </Box> */}
        
    </Box>
  )
}

export default Header